.custom-jitsi-container {
    background-color: #F5F5F5;
    /* display: flex; */
    width:100%;
    /* height:100%; */
    /* height: 80vh; */
  }
  
  .custom-jitsi-container .toolbox-container {
    background-color: #F5F5F5;
  }
  
  .custom-jitsi-container .toolbox-button {
    color: #333333;
  }
  
  .custom-jitsi-container .toolbox-icon {
    fill: #FFFFFF;
  }
  
  .custom-jitsi-container .toolbox-label {
    color: #333333;
  }
  
  /* .custom-jitsi-container .filmstrip-watermark {
    background-image: url(https://example.com/logo.png);
  } */
  
  .custom-jitsi-container .filmstrip-watermark img {
    display: none;
  }
  