:root {
	--switches-bg-color: whitesmoke;
	--switches-label-color: goldenrod;
	--switch-bg-color: goldenrod;
	--switch-text-color: whitesmoke;
}

.input:focus {
	outline: none;
}

*:focus {
	outline: none;
}

.dot-pulse {
	position: relative;
	left: -9999px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #ffffff;
	color: #ffffff;
	box-shadow: 9999px 0 0 -5px;
	animation: dot-pulse 1.5s infinite linear;
	animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: #ffffff;
	color: #ffffff;
}
.dot-pulse::before {
	box-shadow: 9984px 0 0 -5px;
	animation: dot-pulse-before 1.5s infinite linear;
	animation-delay: 0s;
}
.dot-pulse::after {
	box-shadow: 10014px 0 0 -5px;
	animation: dot-pulse-after 1.5s infinite linear;
	animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
	0% {
		box-shadow: 9984px 0 0 -5px;
	}
	30% {
		box-shadow: 9984px 0 0 2px;
	}
	60%,
	100% {
		box-shadow: 9984px 0 0 -5px;
	}
}
@keyframes dot-pulse {
	0% {
		box-shadow: 9999px 0 0 -5px;
	}
	30% {
		box-shadow: 9999px 0 0 2px;
	}
	60%,
	100% {
		box-shadow: 9999px 0 0 -5px;
	}
}
@keyframes dot-pulse-after {
	0% {
		box-shadow: 10014px 0 0 -5px;
	}
	30% {
		box-shadow: 10014px 0 0 2px;
	}
	60%,
	100% {
		box-shadow: 10014px 0 0 -5px;
	}
}

.chat-grid::-webkit-scrollbar {
display: none;
}

/* a container - decorative, not required */
/* 
.container {
	border: 1px solid plum;
	width: 20rem;
	padding: 1rem;
	margin-left: auto;
	margin-right: auto;
	margin-top: 5%;
}
/* p - decorative, not required */
/* p {
	margin-top: 2rem;
	font-size: 0.75rem;
	text-align: center;
} */ 

/* container for all of the switch elements 
    - adjust "width" to fit the content accordingly 
*/
.switches-container {
	width: 10rem;
	position: relative;
	display: flex;
	padding: 0;
	position: relative;
	background: var(--switches-bg-color);
	line-height: 2.5rem;
	border-radius: 3rem;
	margin-left: auto;
	margin-right: auto;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
	visibility: hidden;
	position: absolute;
	top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
	width: 50%;
	padding: 0;
	margin: 0;
	text-align: center;
	cursor: pointer;
	color: var(--switches-label-color);
}

/* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 50%;
	padding: 0.15rem;
	z-index: 3;
	transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
	/* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
	border-radius: 3rem;
	background: var(--switch-bg-color);
	height: 100%;
}

/* switch box labels
    - default setup
    - toggle afterwards based on radio:checked status 
*/
.switch div {
	width: 100%;
	text-align: center;
	opacity: 0;
	display: block;
	color: var(--switch-text-color);
	transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
	will-change: opacity;
	position: absolute;
	top: 0;
	left: 0;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
	transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
	transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container
	input:nth-of-type(1):checked
	~ .switch-wrapper
	.switch
	div:nth-of-type(1) {
	opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container
	input:nth-of-type(2):checked
	~ .switch-wrapper
	.switch
	div:nth-of-type(2) {
	opacity: 1;
}
